import type { PropsWithChildren } from 'react';
import { createContext, useState } from 'react';

import noop from 'lodash/noop';

// Create Context Object
export const OverlayContext = createContext<{
  shown: boolean;
  setShownDialog: (val: boolean) => void;
  setShownPrompt: (val: boolean) => void;
}>({
  shown: false,
  setShownPrompt: noop,
  setShownDialog: noop,
});

// Create a provider for components to consume and subscribe to changes
export const OverlayProvider = ({ children }: PropsWithChildren<{}>) => {
  const [shownPrompt, setShownPrompt] = useState(false);
  const [shownDialog, setShownDialog] = useState(false);

  return (
    <OverlayContext.Provider value={{ shown: shownPrompt || shownDialog, setShownDialog, setShownPrompt }}>
      {children}
    </OverlayContext.Provider>
  );
};
